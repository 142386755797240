import { addLike, addView, fetchTemplates } from "./actions";
import { toast } from "react-toastify";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { CatalogSliceState } from "./catalog";
import sortBoards from "../../domain/utils/sortBoards";

type ReducerBuilder = ActionReducerMapBuilder<CatalogSliceState>;

export function createFetchTemplatesReducer(builder: ReducerBuilder) {
  builder.addCase(fetchTemplates.pending, (state) => {
    state.isLoading = true;
    state.isLoaded = false;
  });
  
  builder.addCase(fetchTemplates.fulfilled, (state, action) => {
    state.isLoading = false;
    state.isLoaded = true;
    state.templates = action.payload.map(template => {
      template.boards = sortBoards(template.boards)
      return template
    }) as any;
  });
  
  builder.addCase(fetchTemplates.rejected, (state) => {
    toast.error('Не удалось загрузить каталог')
    state.isLoading = false;
    state.isLoaded = true;
  });
}

export function createAddLikeReducer(builder: ReducerBuilder) {
  builder.addCase(addLike.fulfilled, (state, action) => {
    state.templates = state.templates.map(template => {
      if (template.id === action.payload.id) {
        action.payload.boards = sortBoards(action.payload.boards)
        return action.payload
      }
      return template
    }) as any
  });
  
  builder.addCase(addLike.rejected, (state) => {
    toast.error('Не удалось поставить лайк')
  });
}

export function createAddViewReducer(builder: ReducerBuilder) {
  builder.addCase(addView.fulfilled, (state, action) => {
    state.templates = state.templates.map(template => {
      if (template.id === action.payload.id) {
        action.payload.boards = sortBoards(action.payload.boards)
        return action.payload
      }
      return template
    }) as any
  });
  
  builder.addCase(addView.rejected, (state) => {
    toast.error('Не удалось засчитать просмотр')
  });
}
