import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function StarIcon(props: IProps) {
  const {
    width,
    height,
    fill,
    ...rest
  } = props;
  
  return (
    <svg width={width ?? 21} style={{ minWidth: width ?? 21, minHeight: height ?? 20 }} height={height ?? 20}
         viewBox="0 0 21 20" fill="none"
         xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M9.70843 1.82368C9.93892 1.35674 10.0542 1.12327 10.2106 1.04868C10.3467 0.983775 10.5049 0.983775 10.641 1.04868C10.7974 1.12327 10.9127 1.35674 11.1432 1.82368L13.3298 6.25364C13.3979 6.3915 13.4319 6.46042 13.4816 6.51394C13.5257 6.56132 13.5785 6.59971 13.6371 6.62698C13.7033 6.65778 13.7794 6.6689 13.9315 6.69113L18.8228 7.40607C19.3378 7.48135 19.5954 7.519 19.7146 7.6448C19.8183 7.75425 19.867 7.90466 19.8473 8.05414C19.8246 8.22594 19.6382 8.40754 19.2653 8.77074L15.7272 12.2168C15.6169 12.3242 15.5618 12.3779 15.5262 12.4418C15.4947 12.4984 15.4745 12.5606 15.4667 12.6249C15.4579 12.6975 15.4709 12.7734 15.4969 12.9251L16.3317 17.7925C16.4198 18.3059 16.4638 18.5625 16.3811 18.7149C16.3091 18.8474 16.1811 18.9403 16.0328 18.9678C15.8624 18.9994 15.6319 18.8782 15.1709 18.6358L10.7982 16.3362C10.6619 16.2645 10.5938 16.2287 10.522 16.2146C10.4585 16.2022 10.3931 16.2022 10.3296 16.2146C10.2578 16.2287 10.1897 16.2645 10.0534 16.3362L5.6807 18.6358C5.2197 18.8782 4.98919 18.9994 4.81875 18.9678C4.67046 18.9403 4.5425 18.8474 4.47052 18.7149C4.38778 18.5625 4.43181 18.3058 4.51986 17.7925L5.35467 12.9251C5.38069 12.7734 5.39371 12.6975 5.3849 12.6249C5.3771 12.5606 5.35689 12.4984 5.32539 12.4418C5.2898 12.3779 5.23466 12.3242 5.12437 12.2168L1.58634 8.77074C1.21344 8.40754 1.027 8.22594 1.00431 8.05414C0.984567 7.90466 1.03333 7.75425 1.13703 7.6448C1.25622 7.519 1.51375 7.48135 2.02882 7.40607L6.92009 6.69113C7.07221 6.6689 7.14826 6.65778 7.2145 6.62698C7.27315 6.59971 7.32594 6.56132 7.36997 6.51394C7.41969 6.46042 7.45371 6.3915 7.52175 6.25364L9.70843 1.82368Z"
        stroke="currentColor" fill={fill ?? 'none'} strokeWidth="1.5" strokeLinecap="round"
        strokeLinejoin="round"/>
    </svg>
  );
}
