import { Board } from "../../../core/domain/interfaces/supabase/overlay/entity";

export default function sortBoards(boards: Board[]) {
  const favorite = boards.filter((b) => b.isFavorite);
  const others = boards.filter((b) => !b.isFavorite);
  
  const sortByTime = (a: Board, b: Board) => a.createdAt.getTime() - b.createdAt.getTime();
  
  return [
    ...favorite.sort(sortByTime),
    ...others.sort(sortByTime),
  ];
}
