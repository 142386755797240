import React, { useCallback, useMemo, useState } from 'react';
import EmptyButton from "../../../emptyButton";
import { increaseClickArea } from "../../../../../template-editor/ui/pages/styles";
import NewEditIcon from "../../../icon/new-edit-icon";
import NewTrashIcon from "../../../icon/new-trash-icon";
import { AppDispatch } from "../../../../../../store/store";
import { useDispatch } from "react-redux";
import useDeleteModal from "../../../../../template-editor/ui/hooks/modals/board/delete";
import useRenameModal from "../../../../../template-editor/ui/hooks/modals/board/rename";
import useBoards from "../../../../../template-editor/ui/hooks/useBoards";
import { Board } from "../../../../../core/domain/interfaces/supabase/overlay/entity";
import { favoriteBoard, setActiveBoard } from "../../../../../template-editor/store/templates/templates";
import * as S from './styles'
import UDText from "../../../text";
import StarIcon from "../../../icon/star-icon";
import { updateBoard } from "../../../../../template-editor/store/templates/actions";

interface IProps {
  board: Board
  isEditable: boolean
  onClick: () => void
  isActive?: boolean
}

function BoardItem(props: IProps) {
  const dispatch = useDispatch<AppDispatch>()
  const { board, isEditable, onClick, isActive } = props
  const { modal: renameModal, open: openRenameModal } = useRenameModal()
  const { modal: deleteModal, open: openDeleteModal } = useDeleteModal()
  const [isHover, setIsHover] = useState(false)
  const { boards } = useBoards()
  
  const isLast = boards.length === 1
  
  const onEditClick = () => {
    setIsHover(false)
    dispatch(setActiveBoard({ id: board.id }))
    openRenameModal()
  }
  
  const onDeleteClick = () => {
    setIsHover(false)
    dispatch(setActiveBoard({ id: board.id }))
    openDeleteModal()
  }
  
  const onFavoriteClick = useCallback(() => {
    dispatch(updateBoard({ id: board.id, isFavorite: !board.isFavorite }))
    dispatch(favoriteBoard({ id: board.id }))
  }, [board.id, board.isFavorite, dispatch]);
  
  const favoriteIsAvailable = useMemo(() => {
    let count = 0
    for (const b of boards) {
      b.isFavorite && count++
    }
    return count < 3
  }, [boards]);
  
  return (
    <S.BoardItem
      onClick={onClick}
      key={board.id}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: isActive ? '#9093AA' : 'transparent',
        color: isActive ? '#FFFFFF' : '#1A1A29',
      }}
    >
      <UDText style={{ color: 'inherit' }} type={'subhead'}>{board.name}</UDText>
      {isEditable && (isHover ? (
        <div style={{ display: 'flex', gap: 18, justifyContent: 'center', alignItems: 'center' }}>
          <EmptyButton
            color={isActive ? '#FFFFFF' : '#1A1A29'}
            css={increaseClickArea({ width: 40, height: 40 })}
            onClick={onEditClick}
            icon={<NewEditIcon width={24} height={24}/>}
          />
          {!isLast && (
            <EmptyButton
              color={isActive ? '#FFFFFF' : '#1A1A29'}
              css={increaseClickArea({ width: 40, height: 40 })}
              onClick={onDeleteClick}
              icon={<NewTrashIcon width={24} height={24}/>}
            />
          )}
          {(favoriteIsAvailable || board.isFavorite) && (
            <EmptyButton
              color={board.isFavorite ? '#FFD400' : isActive ? '#FFFFFF' : '#1A1A29'}
              css={increaseClickArea({ width: 40, height: 40 })}
              onClick={onFavoriteClick}
              icon={<StarIcon
                width={20}
                height={20}
                fill={board.isFavorite ? '#FFD400' : 'transparent'}
              />}
            />
          )}
        </div>
      ) : (
        board.isFavorite && (
          <StarIcon width={20} height={20} color={'#FFD400'} fill={'#FFD400'}/>
        )
      ))}
      {renameModal}
      {deleteModal}
    </S.BoardItem>
  )
}

export default BoardItem;
