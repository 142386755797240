import styled from '@emotion/styled';
import React from 'react';

interface IGoalProps {
  data?: {
    teamLogo: string
    leagueLogo: string
    cards: number
    minute: number
    lastName: string
  }
  color: 'yellow' | 'red'
}

function Card(props: IGoalProps) {
  const { cards, minute, lastName, leagueLogo, teamLogo } = props.data ?? {}
  const { color } = props
  
  return (
    <MainElem className={'d-flex'}>
      <div className={'d-flex flex-column'} style={{ gap: 1 }}>
        <div className={'d-flex flex-row'} style={{ gap: 2 }}>
          <div className={'d-flex flex-row'}>
            <LeagueLogo className={'flex-center'}>
              <img src={leagueLogo} style={{ width: '100%', padding: 4 }} alt={'LeagueLogo'}/>
            </LeagueLogo>
            <Strip width={2}/>
          </div>
          <TeamLogo className={'flex-center'}>
            <img src={teamLogo} alt={'TeamLogo'} style={{ width: '100%', padding: 4 }}/>
          </TeamLogo>
          <Cards color={color} className={'flex-center'}>
            <span>{cards}</span>
          </Cards>
        </div>
        <Time className={'px-3'}>мин. {minute}`</Time>
      </div>
      <LastName className={'d-flex align-items-center'}>
        {lastName}
      </LastName>
    </MainElem>
  );
}

export default Card;

const MainElem = styled.div`
  width: 392px;
  height: 82px;
  
  position: absolute;
  bottom: 60px;
  left: 30px;
`

const LeagueLogo = styled.div`
  background-color: #F0624A;
  
  width: 50px;
  height: 60px;
`

const TeamLogo = styled.div`
  background-color: #1A1A29;
  
  width: 60px;
  height: 60px;
`

const Cards = styled.div<{ color: 'yellow' | 'red' }>`
  background: ${props => props.color === 'red' ? '#A11010' : '#D9B244'};
  
  width: 50px;
  height: 60px;
  
  color: white;
  font-family: Big Shoulders Display, sans-serif;
  font-size: 40px;
  font-weight: 900;
  line-height: 26px;
  text-align: center;
`

const Time = styled.div`
  background: #ffffff;
  
  font-family: Big Shoulders Display, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  //text-align: center;

`

const LastName = styled.div`
  background: #F0624A;
  
  width: 230px;
  height: 60px;
  padding: 20px;
  
  color: white;
  font-family: Big Shoulders Display, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
`

const Strip = styled.div<{ width: number }>`
  background-color: #ffffff;
  width: ${props => props.width}px;
  height: 100%;
`
