import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  direction?: 'left' | 'right';
}

export default function DoubleArrowIcon(props: IProps) {
  const {
    width,
    height,
    direction = 'right',
    ...rest
  } = props;
  
  const transformRotation = `rotate(180)`;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 32} height={height ?? 32} viewBox="0 0 32 32"
         transform={direction === 'left' ? transformRotation : ''} {...rest}>
      <title/>
      <g data-name="11-Arrow-direction-pointer" id="_11-Arrow-direction-pointer">
        <polyline
          fill={'none'}
          points="10 31 30 16 10 1"
          stroke={'currentColor'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
          strokeWidth={'2px'}
        />
        <polyline
          fill={'none'}
          points="2 31 22 16 2 1"
          stroke={'currentColor'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
          strokeWidth={'2px'}
        />
      </g>
    </svg>
  );
}
