import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Event } from '../../domain/interfaces/events';

export type OverlaySliceState = {
  isLoading: boolean,
  isLoaded: boolean,
  
  featureIsAvailable?: boolean
  isBoardLocked: boolean
  isHideControlPanel: boolean
  
  events: Event[]
};

const initialState: OverlaySliceState = {
  isLoading: false,
  isLoaded: false,
  isBoardLocked: false,
  isHideControlPanel: false,
  
  events: [],
};

export const overlaySlice = createSlice({
  name: 'overlay',
  initialState,
  reducers: {
    setEvents(state, action: { payload: Event[] }) {
      state.events = action.payload;
    },
    setBoardLocked(state, action: PayloadAction<boolean>) {
      state.isBoardLocked = action.payload;
    },
    setHideControlPanel(state, action: PayloadAction<boolean>) {
      state.isHideControlPanel = action.payload;
    },
  },
});

export const {
  setEvents,
  setBoardLocked,
  setHideControlPanel,
} = overlaySlice.actions
