import styled from '@emotion/styled';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Block from '../components/block';
import Text from '../components/text';
import useBoards from "../../../../template-editor/ui/hooks/useBoards";
import { setActiveBoard } from "../../../../template-editor/store/templates/templates";
import { setBoardLocked } from "../../../store/overlay/overlay";
import { selectIsBoardLocked } from "../../../store/overlay/selectors";
import EmptyButton from "../../../../ud-ui/components/emptyButton";
import LockedIcon from "../../../../ud-ui/components/icon/locked-icon";
import UnlockedIcon from "../../../../ud-ui/components/icon/unlocked-icon";

export function Dashboard() {
  const dispatch = useDispatch()
  const { boards, activeBoard } = useBoards()
  const isBoardLocked = useSelector(selectIsBoardLocked)
  
  const baseCallback = useCallback((newState: number | undefined) => {
    if (newState === activeBoard?.id) {
      dispatch(setActiveBoard({ id: undefined }))
    } else {
      dispatch(setActiveBoard({ id: newState }))
    }
  }, [activeBoard?.id, dispatch]);
  
  const listItems: ItemType[] | undefined = useMemo(() => {
    const listBoards: ItemType[] = boards?.map((board) => {
      return {
        text: board.name,
        state: board.id,
      }
    })
    
    listBoards?.push({ text: 'Выкл.', state: undefined })
    
    return listBoards
  }, [boards])
  
  
  const items = useMemo(() => {
    return listItems?.map((item) => {
      const onClick = () => {
        baseCallback(item.state)
      }
      
      return (
        <Item key={item.state} active={activeBoard?.id === item.state} onClick={onClick} className={'flex-center'}>
          <Text color={'#FFF'} size={'medium'}>{item.text}</Text>
        </Item>
      )
    })
  }, [activeBoard?.id, baseCallback, listItems]);
  
  const toggleLock = useCallback(() => {
    dispatch(setBoardLocked(!isBoardLocked))
  }, [dispatch, isBoardLocked])
  
  return (
    <Block className={'d-flex flex-column'} headerText={'Табло'}>
      <EmptyButton
        style={{ position: 'absolute', right: 5, top: 5, zIndex: 1 }}
        onClick={toggleLock}
        color={isBoardLocked ? 'white' : 'gray'}
        icon={isBoardLocked ? <LockedIcon width={16} height={16}/> : <UnlockedIcon width={16} height={16}/>}
      />
      <DashboardList className={'d-flex'}>
        {items}
      </DashboardList>
    </Block>
  );
}

type ItemType = { text: string, state: number | undefined };

const DashboardList = styled.div`
  gap: 8px;
  flex-wrap: wrap;
`

const Item = styled.div<{ active: boolean }>`
  padding-inline: 14px;
  padding-block: 4px;
  background: ${({ active }) => active ? '#D9B244' : '#686a79'};
`
